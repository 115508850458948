<template>
  <el-calendar v-model="date">
  </el-calendar>
</template>

<script>
export default {
  data () {
    return {
      date: new Date()
    }
  }
}
</script>
